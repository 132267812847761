import _baseIsDate from "./_baseIsDate";
import _baseUnary from "./_baseUnary";
import _nodeUtil from "./_nodeUtil";
var exports = {};
var baseIsDate = _baseIsDate,
  baseUnary = _baseUnary,
  nodeUtil = _nodeUtil;

/* Node.js helper references. */
var nodeIsDate = nodeUtil && nodeUtil.isDate;

/**
 * Checks if `value` is classified as a `Date` object.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a date object, else `false`.
 * @example
 *
 * _.isDate(new Date);
 * // => true
 *
 * _.isDate('Mon April 23 2012');
 * // => false
 */
var isDate = nodeIsDate ? baseUnary(nodeIsDate) : baseIsDate;
exports = isDate;
export default exports;