import _baseGetTag from "./_baseGetTag";
import _isObjectLike from "./isObjectLike";
var exports = {};
var baseGetTag = _baseGetTag,
  isObjectLike = _isObjectLike;

/** `Object#toString` result references. */
var dateTag = "[object Date]";

/**
 * The base implementation of `_.isDate` without Node.js optimizations.
 *
 * @private
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a date object, else `false`.
 */
function baseIsDate(value) {
  return isObjectLike(value) && baseGetTag(value) == dateTag;
}
exports = baseIsDate;
export default exports;